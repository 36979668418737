<template>
	<resource-list-item :title="participationClass.name" :subtitle="subtitle" :divided="props.divided" :to="props.to">

		<div class="mt-8">
			<info-list small>
				<info-list-item v-if="participationClass.status.value !== ParticipationClassStatus.Published" :color="participationClass.status.color" :icon="icons.cancel"
								:text="participationClass.status.title"/>

				<info-list-item v-if="participationClass.is_virtual" :icon="icons.virtual" :text="t(`enum.participation_class_type.${participationClass.type}.title`)" :help="t(`enum.participation_class_type.${participationClass.type}.help`)"/>

				<info-list-item :icon="icons.date" :text="$date(participationClass.start_date).formatDateTimeRange(participationClass.end_date)"/>

				<info-list-item
					v-if="participationClass.has_registration && participationClass.register_start_date && participationClass.register_end_date && $date().isBefore(participationClass.end_date)"
					:icon="icons.register">
                    <span v-if="$date().isBefore(participationClass.register_start_date)">
                        {{ t('text.participation_open_at', {start: $date(participationClass.register_start_date).formatDateTimeRange(false)}) }}
                    </span>
					<span v-else-if="$date().isBefore(participationClass.register_end_date)">{{
							t('text.participation_open_until', {end: $date(participationClass.register_end_date).formatDateTimeRange(true)})
						}}</span>
					<span v-else class="text-warning">{{
							participationClass.register_end_date ? t('text.registration_closed', {end: $date(participationClass.register_end_date).formatDateTimeRange(true)}) : t('label.registration_closed')
						}}</span>
				</info-list-item>

				<info-list-item v-if="participationClass.goal_metric && goalData"
								:icon="goalData.icon"
								:text="`${t(`enum.rank_metric.${participationClass.goal_metric}.goal_metric_title`)}: ${goalData.value}`"/>

			</info-list>
		</div>

		<template v-if="participationClass.currentMinPrice" #right>
			<div class="flex flex-column">
				<template v-if="participationClass.currentMinPrice.cents > 0 || participationClass.currentMaxPrice?.cents > 0">
					<p v-if="participationClass.currentMaxPrice?.cents !== participationClass.currentMinPrice.cents" class="text-success mb-0 text-sub leading-normal text-right text-sm" v-html="t('label.start_end_price_per_unit', {start:
                        toEuro(participationClass.currentMinPrice.cents), end: toEuro(participationClass.currentMaxPrice.cents), unit: participationClass.price_unit_label})"/>
					<p v-else
					   class="text-success mb-0 text-sub leading-normal text-right text-sm" v-html="participationClass.price_valid_until ? t('label.start_price_per_unit', {price:
                        toEuro(participationClass.currentMinPrice.cents), unit: participationClass.price_unit_label}) : t('label.price_per_unit', {price: toEuro(participationClass.currentMinPrice.cents), unit: participationClass.price_unit_label})"/>

					<p class="text-gray-400 text-4xs leading-tight mb-0">{{
							participationClass.currentMinPrice.tax_percent ? t('text.including_tax_and_fees', {tax: participationClass.currentMinPrice.tax_percent}) : t('text.including_fees')
						}}</p>
				</template>
				<p v-else class="text-success text-sub mb-0 text-right text-uppercase text-sm">{{ t('label.free') }}</p>

				<template v-if="$date().isBefore(participationClass.register_end_date)">
					<p v-if="participationClass.price_valid_until" class="text-4xs text-warning leading-tight mt-2 mb-0">{{
							t('text.price.increase', {date: $date(participationClass.price_valid_until).format('LL')})
						}}</p>
				</template>
			</div>
		</template>

	</resource-list-item>
</template>

<script setup lang="ts">
import {mdiCellphoneNfc, mdiElevationRise, mdiLogin, mdiMapMarkerDistance, mdiTimerOutline} from '@mdi/js';
import {ParticipationClassStatus, RankMetric, toEuro} from "@spoferan/spoferan-ts-core";
import {cancelIcon, dateIcon} from "@spoferan/nuxt-spoferan/icons";

const props = defineProps<{
	participationClass: object;
	to?: object|string;
	divided?: boolean;
}>();

const {t, n} = useI18n();

const icons = {
	altitude: mdiElevationRise,
	distance: mdiMapMarkerDistance,
	time: mdiTimerOutline,
	register: mdiLogin,
	virtual: mdiCellphoneNfc,
	date: dateIcon,
	cancel: cancelIcon,
}

const subtitle = computed(() => {
	if (!props.participationClass.sportType) {
		return null;
	}

	let subtitle = props.participationClass.sportType.title;

	if (props.participationClass.format) {
		subtitle += ` | ${props.participationClass.format.title}`;
	}

	return subtitle;
});

const goalData = computed(() => {
	if (!props.participationClass.goal_metric || !props.participationClass.goal_value || props.participationClass.goal_value === '0') {
		return null;
	}

	switch (props.participationClass.goal_metric) {
		case RankMetric.Time:
			return {
				value: getReadableTimeDuration(props.participationClass.goal_value),
				icon: icons.time
			}
		case RankMetric.Distance:
			return {
				value: props.participationClass.goal_value < 1000 ? `${props.participationClass.goal_value} ${t('label.meters')}` : `${n(props.participationClass.goal_value / 1000)} ${t('label.kilometers')}`,
				icon: icons.distance
			}
		case RankMetric.Altitude:
			return {
				value: `${props.participationClass.goal_value} ${t('label.meters')}`,
				icon: icons.altitude
			}
	}
	return null;
});

function getReadableTimeDuration(timeValue) {
	if (!timeValue) {
		return '';
	}

	const timeValueParts = timeValue.split(':');
	const hours = parseInt(timeValueParts[0]);
	const minutes = parseInt(timeValueParts.length > 1 ? timeValueParts[1] : 0);
	const seconds = parseInt(timeValueParts.length > 2 ? timeValueParts[2] : 0);
	const readableParts = [];
	if (hours) {
		readableParts.push(t('param_label.hours', {count: hours}, hours));
	}
	if (minutes) {
		readableParts.push(t('param_label.minutes', {count: minutes}, minutes));
	}
	if (seconds) {
		readableParts.push(t('param_label.seconds', {count: seconds}, seconds));
	}
	return readableParts.join(' ');
}
</script>
