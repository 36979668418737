<template>
	<card xs-tile sm-tile>
		<card-title>
			<div class="w-full flex justify-between items-center">
				<h2 v-if="!props.participantsCount">{{
						t('param_label.community_participants', {count: n(props.communityParticipantsCount)}, props.communityParticipantsCount)
					}}</h2>
				<h2 v-else-if="eventStore.isFinished">{{ t('param_label.started_participants', {count: n(props.participantsCount)}, props.participantsCount) }}</h2>
				<h2 v-else-if="!eventStore.event.has_managed_sign_up">{{ t('param_label.expected_participants', {count: n(props.participantsCount)}, props.participantsCount) }}</h2>
				<h2 v-else>{{ t('param_label.registered_participants', {count: n(props.participantsCount)}, props.participantsCount) }}</h2>

				<nuxt-link v-if="eventStore.hasActivePage(EventPageType.Participations)" class="text-sm font-regular font-text" :style="{color: eventStore.colorStyle}"
						   :to="$web('events.participations.index', [eventStore.event._key])">{{ $t('action.show_all') }}
				</nuxt-link>
			</div>
		</card-title>
		<card-subtitle
			v-if="!eventStore.event.has_managed_sign_up && props.communityParticipantsCount && props.participantsCount && props.communityParticipantsCount !== props.participantsCount">
			{{ t('param_label.community_participants', {count: n(props.communityParticipantsCount)}, props.communityParticipantsCount) }}
		</card-subtitle>
		<card-text v-if="props.communityParticipantsCount">
			<avatar-list-skeleton v-if="participationsPreviewDataPending" :size="36" :max="Math.min(10, Math.max(props.participantsCount, props.communityParticipantsCount))"
								  no-wrap/>
			<avatar-list v-else
						 :models="participationsPreviewData ?? []"
						 :size="36"
						 no-wrap
						 :show-all-resolver="eventStore.hasActivePage(EventPageType.Participations) ? () => $web('events.participations.index', [eventStore.event._key]) : undefined"
						 :show-resolver="(model) => $web('events.participations.show', [eventStore.event._key, model._key])"
						 :total="Math.max(props.participantsCount, props.communityParticipantsCount)"
						 :max="10"/>
		</card-text>
		<btn v-if="!eventStore.isParticipant && !eventStore.event.has_managed_sign_up" @click="eventStore.openAddParticipationModal" text
			 :color="eventStore.colorStyle" class="rounded-t-none border-t w-full">
			<icon :icon="icons.register"/>
			<span>{{ $t('action.add_own_participation') }}</span>
		</btn>
	</card>
</template>

<script lang="ts" setup>
import {EventPageType} from "@spoferan/spoferan-ts-core";
import {mdiLogin} from "@mdi/js";
import {useEventStore} from "../../store/event";

const props = defineProps<{
	participantsCount: number,
	communityParticipantsCount: number,
}>();

const eventStore = useEventStore();
const {t, n} = useI18n();

const {
	reactiveData: participationsPreviewData,
	pending: participationsPreviewDataPending
} = props.communityParticipantsCount ? (await useApiFetch(`/v1/view/events/${eventStore.event.id}/participations/preview`, {
	lazy: true,
	server: false,
	auth: true
})) : {reactiveData: ref(null)};


const icons = {
	register: mdiLogin,
};
</script>